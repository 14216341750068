import React, { ReactElement, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { ApolloError, useMutation } from '@apollo/client';
import { NewButton } from '@odin-labs/components';
import {
  JobsiteWorkerOrientationStatus,
  JobsiteWorker,
  MutationUpdateJobsiteWorkerArgs,
  WorkerCreatedMethod,
  UserErrorCode,
  AppErrorCode,
  ContractorWorker,
  useGetAddJobsiteContractorWorkerJobsiteQuery,
  useAssignContractorWorkerToJobsiteMutation,
} from 'apollo/generated/client-operations';
import { AlertInstance } from 'components/alertNotification';
import { LoadingError } from 'components/loadingError';
import { Header } from 'components/header';
import { Container, Row, Col } from 'reactstrap';
import { getGraphQLError, getGraphQLErrorByCode, getGraphQLErrorByName } from 'utils/error';
import { Form, FormFieldsConfig, FormOnSubmit, HTMLFormElementWithApi } from 'components/form';
import { FullLayoutNavbarContainer } from 'containers/fullLayoutNavbar';
import { momentISODateFormatter, getCurrentISOFormattedDate, getPhoneNumberAsE164 } from 'utils';
import { WorkersFound } from 'components/modals/types';
import { WorkersFoundModal } from 'components/modals/WorkersFoundModal';
import { getCurrentISOFormattedDateTime, momentFormatter } from 'utils/dates';
import { UPDATE_JOBSITE_WORKER } from 'containers/workerOnboarding/helpers/queries';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { getFormInputs, getDefaultValues, configFieldMap } from './AddJobsiteContractorWorkerContainer.forms';
import { CREATE_CONTRACTOR_WORKER } from './helpers/queries';
import { CreateContractorWorkerResponse, CreateContractorWorkerVariables, BasicInfoFormData } from './types';
import { OnboardingStepKey, getStepFieldsConfig } from './helpers/utils';

export function AddJobsiteContractorWorkerContainer(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const { contractorId, jobsiteId } = useParams<{ contractorId: string; jobsiteId: string }>();
  const params = queryString.parse(location.search);
  const [fetching, setFetching] = useState<boolean>(false);
  const [workerMatch, setWorkerMatch] = useState<WorkersFound>(null);
  const [basicInfo, setBasicInfo] = useState<BasicInfoFormData>();

  const { orientationDate, workType, tenant } = params;

  const [existingWorkers, setExistingWorkers] = useState<WorkersFound[]>([]);

  const {
    data: jobsiteData,
    error: jobsiteError,
    loading: jobsiteLoading,
  } = useGetAddJobsiteContractorWorkerJobsiteQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteId },
    skip: !jobsiteId,
  });

  const jobsite = jobsiteData?.getJobsite;

  const [updateJobsiteWorker] = useMutation<JobsiteWorker, MutationUpdateJobsiteWorkerArgs>(UPDATE_JOBSITE_WORKER, {
    onError: (error) => {
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
    },
    refetchQueries: ['GetJobsiteWorker'],
  });

  const [assignContractorWorkerToJobsite] = useAssignContractorWorkerToJobsiteMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setFetching(false);
      const { orientationStatus, jobsiteWorkerId, contractorWorker } =
        data?.assignContractorWorkerToJobsite.jobsiteWorker ?? {};

      if (moment(orientationDate).format(momentISODateFormatter) === getCurrentISOFormattedDate()) {
        if (orientationStatus === JobsiteWorkerOrientationStatus.NotStarted) {
          updateJobsiteWorker({
            variables: {
              jobsiteWorkerId,
              jobsiteWorkerInput: {
                orientationStatus: JobsiteWorkerOrientationStatus.InProgress,
                orientationDate: getCurrentISOFormattedDateTime(),
                isAntipassbackExempt: false,
                workType: workType as string,
                fitoutTenant: tenant as string,
              },
            },
          });
        }
        history.push(`/onboarding/${jobsiteWorkerId}/personal-information`);
      } else {
        updateJobsiteWorker({
          variables: {
            jobsiteWorkerId,
            jobsiteWorkerInput: {
              orientationStatus: JobsiteWorkerOrientationStatus.NotStarted,
              isAntipassbackExempt: false,
              workType: workType as string,
              fitoutTenant: tenant as string,
            },
          },
        });
        history.push(`/worker/${contractorWorker?.worker?.workerId}`);
      }
    },
    onError: (responseError: ApolloError) => {
      setFetching(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
  });

  const formRef = React.useRef<HTMLFormElementWithApi<BasicInfoFormData>>();

  // creates a new contactor worker
  const [createContractorWorker] = useMutation<CreateContractorWorkerResponse, CreateContractorWorkerVariables>(
    CREATE_CONTRACTOR_WORKER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (data: CreateContractorWorkerResponse) => {
        setWorkerMatch(null);
        assignContractorWorkerToJobsite({
          variables: {
            input: {
              workerId: data?.createContractorWorker?.worker?.workerId,
              jobsiteId,
              contractorId,
              jobsiteWorkerInput: {
                startDate: getCurrentISOFormattedDate(),
                orientationDate: orientationDate ? moment(orientationDate).format(momentISODateFormatter) : null,
              },
            },
          },
        });
      },
      onError: (error: ApolloError) => {
        setFetching(false);
        const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
          error,
          UserErrorCode.EmailAlreadyExists,
        );
        if (emailAlreadyExistsError) {
          formRef.current.api.setError('email', {
            message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
            shouldFocus: true,
          });
          return;
        }
        const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
          error,
          AppErrorCode.PhoneAlreadyExists,
        );
        if (phoneAlreadyExistsError) {
          formRef.current.api.setError('phoneNumber', {
            message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
            shouldFocus: true,
          });
          return;
        }

        const workerAlreadyAssignedToContractorError = getGraphQLErrorByName<{ contractorWorker: ContractorWorker }>(
          error,
          'WorkerAlreadyAssignedToContractorError',
        );
        if (workerAlreadyAssignedToContractorError) {
          const worker = workerAlreadyAssignedToContractorError.extensions?.contractorWorker?.worker;
          const currentWorkerMatch = {
            firstName: worker?.user?.identity?.firstName,
            lastName: worker?.user?.identity?.lastName,
            title: worker?.jobTitle,
            trade: worker?.trade,
            workerId: worker?.workerId,
          };
          setExistingWorkers([currentWorkerMatch]);
          return;
        }

        const workerAlreadyExistsError = getGraphQLErrorByName<{ workerInfo: WorkersFound[] }>(
          error,
          'WorkerAlreadyExistsError',
        );
        if (workerAlreadyExistsError) {
          const users = workerAlreadyExistsError.extensions?.workerInfo;
          setExistingWorkers(users);
          return;
        }
        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      },
    },
  );

  const getCreateContractorWorkerVariables = (
    data: BasicInfoFormData,
    forceCreate: boolean,
  ): CreateContractorWorkerVariables => ({
    createContractorWorkerInput: {
      forceCreate,
      createdMethod: WorkerCreatedMethod.WebApp,
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      birthDate: moment.utc(data.birthDate, momentFormatter).format(momentISODateFormatter),
      ssnLastFour: data.ssnLastFour?.trim() || null,
      contractorId,
      phoneNumber: getPhoneNumberAsE164(data.phoneNumber) || null,
      email: data.email || null,
      middleInitial: data.middleInitial || null,
      suffix: data.suffix || null,
      contractorWorkerInput: {
        startDate: getCurrentISOFormattedDate(),
      },
    },
  });

  const onSubmit: FormOnSubmit<BasicInfoFormData> = (data): void => {
    if (fetching) {
      return;
    }

    setFetching(true);
    setBasicInfo(data);

    createContractorWorker({ variables: getCreateContractorWorkerVariables(data, false) });
  };

  const formInputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues(basicInfo), [basicInfo]);
  const fieldsConfig = React.useMemo(
    (): FormFieldsConfig => ({
      fields: getStepFieldsConfig(OnboardingStepKey.PersonalInfo, jobsite),
      inputToFieldMap: configFieldMap,
    }),
    [jobsite],
  );

  if (jobsiteError) {
    return <LoadingError error={jobsiteError} />;
  }

  const loading = fetching || jobsiteLoading;

  return (
    <Container fluid className="add-jobsite-contractor-worker-container">
      <FullLayoutNavbarContainer fixed="false" />
      <Row className="justify-content-center">
        <Col lg="8">
          <Header
            title="Let's start with the basics."
            subtitle="Please enter the following information about the worker."
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="8">
          {loading && (
            <div className="table-backdrop odin-m--5">
              <LoadingError loading />
            </div>
          )}
          <Form
            ref={formRef}
            inputs={formInputs}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            renderBelow={(): ReactElement => (
              <div className="odin-mt-6 odin-space-y-6">
                <NewButton type="submit" text="Submit" withSpinner={fetching} />
              </div>
            )}
            className="odin-p-3"
            inputsContainerClassName="odin-grid odin-grid-cols-12 odin-gap-6"
            fieldsConfig={fieldsConfig}
          />
        </Col>
      </Row>
      <WorkersFoundModal
        isOpen={!!existingWorkers.length}
        workers={existingWorkers}
        setWorkerMatch={setWorkerMatch}
        toggle={(): void => {
          setExistingWorkers([]);
        }}
        workerMatch={workerMatch}
        onCancel={(): void => {
          if (basicInfo) {
            createContractorWorker({ variables: getCreateContractorWorkerVariables(basicInfo, true) });
          }
          setExistingWorkers([]);
        }}
        onAction={(): void => {
          history.push(`/worker/${workerMatch?.workerId}`);
        }}
      />
    </Container>
  );
}
