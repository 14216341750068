import { ApolloError, useMutation } from '@apollo/client';
import {
  AppErrorCode,
  UserErrorCode,
  WorkerCardType,
  WorkerCreatedMethod,
  ContractorWorker,
  useAddCardToWorkerMutation,
  useGetIssueTempBadgeJobsiteQuery,
  useGetJobsiteCardFormatsQuery,
  useUpdateContractorWorkerMutation,
} from 'apollo/generated/client-operations';
import { Modal } from '@odin-labs/components';
import { AlertInstance } from 'components/alertNotification';
import { LoadingError } from 'components/loadingError';
import { WorkersFound } from 'components/modals/types';
import { WorkersFoundModal } from 'components/modals/WorkersFoundModal';
import { SelectOptionElement } from 'components/select/types';
import { Wizard, WizardRef, WizardStepProps } from 'components/wizard';
import { CREATE_CONTRACTOR_WORKER } from 'containers/jobsiteContractorWorker/helpers/queries';
import {
  CreateContractorWorkerResponse,
  CreateContractorWorkerVariables,
} from 'containers/jobsiteContractorWorker/types';
import moment from 'moment';
import React, { ReactElement, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { getCurrentISOFormattedDate, momentFormatter, momentISODateFormatter } from 'utils/dates';
import { getGraphQLError, getGraphQLErrorByCode, getGraphQLErrorByName } from 'utils/error';
import { ensureNonEmptyItems, getPhoneNumberAsE164, useIsMounted, useResettableState } from 'utils';
import { AlreadyExistingItem } from 'components/alreadyExistingItem';
import { FormOnSubmit, HTMLFormElementWithApi } from 'components/form';
import { getContractorsOptions } from 'containers/contractor/helpers';
import {
  ReassignBadgeModalContent,
  ReassignBadgeState,
  WorkerCardAlreadyExistsErrorExtensions,
} from 'containers/worker/modals/addBadgeModal/components';
import { AlreadyExistingBadgeMessage } from 'components/alreadyExistingBadgeMessage';
import { EmailAlreadyExistsErrorExtensions, PhoneAlreadyExistsErrorExtensions } from 'types';
import { getStep1DefaultValues, getStep1FormInputs, getStep2DefaultValues, getStep2FormInputs } from './helpers/forms';
import { BadgeInfoFormData, RouteParams, WorkerInfoFormData } from './types';

export function IssueTempBadgeContainer(): ReactElement {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [workerMatch, setWorkerMatch] = useState<WorkersFound>(null);
  const [existingWorkers, setExistingWorkers] = useState<WorkersFound[]>([]);
  const [basicInfo, setBasicInfo] = useState<WorkerInfoFormData>();
  const [createdWorkerId, setCreatedWorkerId] = useState<string>('');
  const {
    value: reassignBadgeState,
    setValue: openReassignBadgeModal,
    resetValue: closeReassignBadgeModal,
  } = useResettableState<ReassignBadgeState>(null, null);

  const { jobsiteId, workerId } = useParams<RouteParams>();
  const history = useHistory();
  const wizardRef = useRef<WizardRef>();

  const [addCardToWorker] = useAddCardToWorkerMutation();

  const [updateContractorWorker] = useUpdateContractorWorkerMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setLoading(false);
      AlertInstance.alert('tc', 'success', 'Success', 'User added');
      wizardRef.current.onNextStep();
    },
    onError: (responseError: ApolloError) => {
      setLoading(false);
      AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(responseError));
    },
  });

  const step1FormRef = React.useRef<HTMLFormElementWithApi<WorkerInfoFormData>>();

  const [createContractorWorker] = useMutation<CreateContractorWorkerResponse, CreateContractorWorkerVariables>(
    CREATE_CONTRACTOR_WORKER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (responseData: CreateContractorWorkerResponse) => {
        const worker = responseData?.createContractorWorker?.worker;
        setCreatedWorkerId(worker?.workerId);

        await updateContractorWorker({
          variables: {
            workerId: worker?.workerId,
            workerInput: {
              trade: basicInfo?.trade?.value || '',
            },
            assignContractorWorkerToJobsiteInput: {
              workerId: worker?.workerId,
              jobsiteId,
              contractorId: basicInfo?.company?.value,
              jobsiteWorkerInput: {
                startDate: moment().toDate(),
              },
            },
          },
        });
      },
      onError: (error: ApolloError) => {
        setLoading(false);

        const emailAlreadyExistsError = getGraphQLErrorByCode<EmailAlreadyExistsErrorExtensions>(
          error,
          UserErrorCode.EmailAlreadyExists,
        );
        if (emailAlreadyExistsError) {
          step1FormRef.current.api.setError('email', {
            message: <AlreadyExistingItem itemType="Email" workerId={emailAlreadyExistsError.extensions.workerId} />,
            shouldFocus: true,
          });
          return;
        }
        const phoneAlreadyExistsError = getGraphQLErrorByCode<PhoneAlreadyExistsErrorExtensions>(
          error,
          AppErrorCode.PhoneAlreadyExists,
        );
        if (phoneAlreadyExistsError) {
          step1FormRef.current.api.setError('phoneNumber', {
            message: <AlreadyExistingItem itemType="Phone" workerId={phoneAlreadyExistsError.extensions.workerId} />,
            shouldFocus: true,
          });
          return;
        }

        const workerAlreadyAssignedToContractorError = getGraphQLErrorByName<{ contractorWorker: ContractorWorker }>(
          error,
          'WorkerAlreadyAssignedToContractorError',
        );
        if (workerAlreadyAssignedToContractorError) {
          const worker = workerAlreadyAssignedToContractorError.extensions?.contractorWorker?.worker;
          const currentWorkerMatch = {
            firstName: worker?.user?.identity?.firstName,
            lastName: worker?.user?.identity?.lastName,
            title: worker?.jobTitle,
            trade: worker?.trade,
            workerId: worker?.workerId,
          };
          setExistingWorkers([currentWorkerMatch]);
          return;
        }

        const workerAlreadyExistsError = getGraphQLErrorByName<{ workerInfo: WorkersFound[] }>(
          error,
          'WorkerAlreadyExistsError',
        );
        if (workerAlreadyExistsError) {
          const users = workerAlreadyExistsError.extensions?.workerInfo;
          setExistingWorkers(users);
          return;
        }

        AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(error));
      },
    },
  );

  const {
    data: jobsiteData,
    error: jobsiteError,
    loading: jobsiteLoading,
  } = useGetIssueTempBadgeJobsiteQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteId },
    skip: !jobsiteId,
  });

  const {
    data: getJobsiteCardFormatsData,
    error: getJobsiteCardFormatsError,
    loading: getJobsiteCardFormatsLoading,
  } = useGetJobsiteCardFormatsQuery({
    fetchPolicy: 'no-cache',
    variables: { jobsiteId },
    skip: !jobsiteId,
  });

  const jobsite = jobsiteData?.getJobsite;
  const contractors = jobsiteData?.getJobsite.jobsiteContractors.edges.map(({ node }) => node.contractor);
  const contractorOptions = React.useMemo(() => getContractorsOptions(contractors) ?? [], [contractors]);

  const jobsiteCardFormats = getJobsiteCardFormatsData?.getJobsiteCardFormats;
  /** make sure the default card format the first item in the list */
  const { cardFormatOptions, defaultBluetoothCardFormat } = React.useMemo(() => {
    return {
      cardFormatOptions:
        jobsiteCardFormats
          ?.filter(({ workerCardFormat: { cardType } }) => cardType === WorkerCardType.Proximity)
          .sort(({ isDefaultForCardType }) => (isDefaultForCardType != null ? -1 : 1))
          .map(
            ({ workerCardFormat: { workerCardFormatId, name, facilityCode, description } }): SelectOptionElement => ({
              value: workerCardFormatId,
              label: [name, facilityCode].filter(Boolean).join(' - '),
              alert: description,
            }),
          ) ?? [],
      defaultBluetoothCardFormat: jobsiteCardFormats?.find(
        ({ isDefaultForCardType }) => isDefaultForCardType === WorkerCardType.Bluetooth,
      ),
    };
  }, [jobsiteCardFormats]);

  const baseUrl = workerId
    ? `/jobsite/${jobsiteId}/worker/${workerId}/issue-badge`
    : `/jobsite/${jobsiteId}/issue-badge`;

  const step1FormInputs = React.useMemo(
    () => getStep1FormInputs({ jobsite, contractorOptions }),
    [jobsite, contractorOptions],
  );
  const step2FormInputs = React.useMemo(() => getStep2FormInputs(cardFormatOptions), [cardFormatOptions]);

  const step1DefaultValues = React.useMemo(() => getStep1DefaultValues(), []);
  const step2DefaultValues = React.useMemo(() => getStep2DefaultValues(cardFormatOptions), [cardFormatOptions]);

  const getCreateContractorWorkerVariables = (
    data: WorkerInfoFormData,
    forceCreate: boolean,
  ): CreateContractorWorkerVariables => ({
    createContractorWorkerInput: {
      forceCreate,
      createdMethod: WorkerCreatedMethod.WebApp,
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      birthDate: moment.utc(data.dateOfBirth, momentFormatter).format(momentISODateFormatter),
      contractorId: data.company?.value,
      contractorWorkerInput: {
        startDate: getCurrentISOFormattedDate(),
      },
      email: data.email || null,
      phoneNumber: getPhoneNumberAsE164(data.phoneNumber) || null,
    },
  });

  const step2FormOnSubmit: FormOnSubmit<BadgeInfoFormData> = async (
    data,
    event,
    dirtyFields,
    formApi,
  ): Promise<void> => {
    const { tapCardNumber, bluetoothBadgeNumber, badgeExpirationDate, accessReason, cardFormatId } = data;
    const cardWorkerId = workerId || createdWorkerId;
    const endDate = badgeExpirationDate.endOf('day').toDate();

    const addCardToWorkerAndRemoveExisting = async (options?: { forceCreate?: boolean }): Promise<void> => {
      const addCardToWorkerResponse = await addCardToWorker({
        variables: {
          jobsiteId,
          workerId: cardWorkerId,
          forceCreate: options?.forceCreate,
          workerCardInput: {
            cardNumber: tapCardNumber,
            startDate: moment().toDate(),
            endDate,
            workerCardFormatId: cardFormatId.value,
            isTemporary: true,
            cardIssueReason: accessReason,
          },
        },
      });

      if (bluetoothBadgeNumber) {
        await addCardToWorker({
          variables: {
            jobsiteId,
            workerId: cardWorkerId,
            forceCreate: options?.forceCreate,
            workerCardInput: {
              cardNumber: bluetoothBadgeNumber,
              startDate: moment().toDate(),
              endDate,
              workerCardFormatId: defaultBluetoothCardFormat?.workerCardFormat?.workerCardFormatId,
              matchingWorkerCardId: addCardToWorkerResponse.data.addCardToWorker.workerCardId,
              isTemporary: true,
              cardIssueReason: accessReason,
            },
          },
        });
      }

      AlertInstance.alert('tc', 'success', 'Success', 'Card added');
      history.push(`/worker/${cardWorkerId}/badges`);
    };

    setLoading(true);
    try {
      await addCardToWorkerAndRemoveExisting();
    } catch (error) {
      const workerCardAlreadyExistsError = getGraphQLErrorByCode<WorkerCardAlreadyExistsErrorExtensions>(
        error,
        AppErrorCode.WorkerCardAlreadyExists,
      );
      if (workerCardAlreadyExistsError) {
        const { scope, workerCardInfo } = workerCardAlreadyExistsError.extensions ?? {};

        if (scope === 'onDifferentWorker') {
          openReassignBadgeModal({
            workerCardInfo,
            reassignBadgeAction: async (): Promise<void> => {
              try {
                setLoading(true);
                await addCardToWorkerAndRemoveExisting({ forceCreate: true });
              } catch (reassignError) {
                AlertInstance.alert('tc', 'danger', 'Something went wrong!', getGraphQLError(reassignError));
              } finally {
                if (isMounted()) setLoading(false);
              }
            },
          });
        } else {
          formApi.setError('tapCardNumber', {
            message: (
              <AlreadyExistingBadgeMessage
                errorMessage={workerCardAlreadyExistsError.message}
                onClick={(): void => history.push(`/worker/${cardWorkerId}/badges`)}
                afterLinkText=" to view badges"
              />
            ),
            shouldFocus: true,
          });
        }
      }
    } finally {
      if (isMounted()) setLoading(false);
    }
  };
  const wizardStepsInput = ensureNonEmptyItems<
    WizardStepProps<WorkerInfoFormData> | WizardStepProps<BadgeInfoFormData>
  >([
    !workerId && {
      name: 'step1',
      title: 'Please tell us about this worker',
      subtitle: `Please enter the following information about the person receiving the badge`,
      path: baseUrl,
      form: {
        ref: step1FormRef,
        inputs: step1FormInputs,
        defaultValues: step1DefaultValues,
        onSubmit: async (data: WorkerInfoFormData): Promise<void> => {
          setBasicInfo(data);
          setLoading(true);
          createContractorWorker({
            variables: getCreateContractorWorkerVariables(data, false),
          });
        },
        className: 'odin-p-3',
        inputsContainerClassName: 'odin-grid odin-grid-cols-12 odin-gap-6',
      },
    },
    {
      name: 'badge-details',
      title: 'Enter the badge details',
      subtitle: `Please enter the following information about the badge the person is receiving`,
      path: `${baseUrl}/badge-details`,
      form: {
        inputs: step2FormInputs,
        defaultValues: step2DefaultValues,
        onSubmit: step2FormOnSubmit,
        className: 'odin-p-3',
        inputsContainerClassName: 'odin-grid odin-grid-cols-12 odin-gap-6',
      },
    },
  ]);

  const queriesLoading = jobsiteLoading || getJobsiteCardFormatsLoading;
  const queriesErrored = jobsiteError || getJobsiteCardFormatsError;

  if (queriesLoading || queriesErrored) {
    return <LoadingError loading={queriesLoading} error={queriesErrored} />;
  }

  return (
    <Container fluid>
      <Wizard
        name="wizard"
        textAlign="center"
        steps={wizardStepsInput}
        loading={loading}
        ref={wizardRef}
        backEnabled={false}
      />
      <WorkersFoundModal
        isOpen={!!existingWorkers.length}
        subtitle="Is this the person who will be receiving the temporary badge?"
        workers={existingWorkers}
        setWorkerMatch={setWorkerMatch}
        toggle={(): void => {
          setExistingWorkers([]);
        }}
        workerMatch={workerMatch}
        cancelText="No"
        actionText="Yes"
        onCancel={(): void => {
          if (basicInfo) {
            createContractorWorker({
              variables: getCreateContractorWorkerVariables(basicInfo, true),
            });
          }
          setExistingWorkers([]);
        }}
        onAction={(): void => {
          setExistingWorkers([]);
          setCreatedWorkerId(workerMatch?.workerId);
          wizardRef.current.onNextStep();
        }}
      />
      <Modal
        open={!!reassignBadgeState}
        setOpen={closeReassignBadgeModal}
        size="md"
        actionsPanel={null}
        title={`This badge is currently assigned to the following worker${
          reassignBadgeState?.workerCardInfo?.length > 1 ? 's' : ''
        }:`}
      >
        <ReassignBadgeModalContent
          setOpen={closeReassignBadgeModal}
          onAction={closeReassignBadgeModal}
          jobsiteId={jobsiteId}
          workerId={workerId || createdWorkerId}
          reassignBadgeState={reassignBadgeState}
        />
      </Modal>
    </Container>
  );
}
